import { algoClient } from '@/algo-client'
import { NFTMinterHandler } from '@/handlers/nftMinterHandler'
import { buildState, readGlobalState, readLocalState } from '@/handlers/utils'
import { walletStore } from '@/stores/wallet-store'
import { observable, when } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class TestViewModel {
  @observable initFinalized = false
  nftMintHandler?: NFTMinterHandler

  constructor() {
    this.init()
    this.test()
  }

  @asyncAction *test() {
    yield when(() => !!this.initFinalized)
    // this.optInMintApplication()
    // const a = yield readGlobalState(algoClient, 121029571)
    // console.log("==========a", a)
    // const b = yield this.nftMintHandler?.getLocalState("PWQN2GKWMPN54MA6SWIEWOES63R7Y26OGHOVNFRJHVL4E6WTY5Q6ZJ5HOI")
    // console.log("==========b", b)
    const a = yield this.nftMintHandler?.isWhitelistMember(walletStore.account)
    // yield this.nftMintHandler?.mintNFT(walletStore.account, 3, "USDC")
  }

  @asyncAction *init() {
    try {
      this.nftMintHandler = new NFTMinterHandler()
    } catch (error) {
    } finally {
      this.initFinalized = true
    }
  }

  @asyncAction *optInMintApplication() {
    try {
        console.log("=======Call optInMintApplication")
      if (this.nftMintHandler) {
        yield this.nftMintHandler?.sendOptInMintApplication(walletStore.account)
      }
    } catch (error) {
    } finally {
    }
  }
}
