



import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { TestViewModel } from './test-viewmodel'

@Observer
@Component({
  components: {},
})
export default class TestPage extends Vue {
    @Provide() vm = new TestViewModel()
}
